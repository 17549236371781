<div class="container" [@navigation]="request.liner ? 'afterNav' : 'beforeNav' ">
  <div class="navbar_block">
    <i class="fas fa-times" (click)="navAnime()"></i>
    <h1 class="navbar_title">Админ</h1>
    <div class="navbar_items">
      <mat-accordion>
        <mat-expansion-panel style="background-color: transparent; box-shadow: 0;" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title style="font-family: Raleway; font-weight: 100; font-size: 21px; color: #E3E6EC; cursor: pointer;">
              Аккаунты
            </mat-panel-title>
          </mat-expansion-panel-header>
          <h2 routerLink="/accounts" routerLinkActive="active">B2C</h2>
          <h2 routerLink="/accountsB2B" routerLinkActive="active">B2B</h2>
        </mat-expansion-panel>
      </mat-accordion>
      <h2 routerLink="/tarifs" routerLinkActive="active">Тарифы</h2>
      <mat-accordion>
        <mat-expansion-panel style="background-color: transparent; box-shadow: 0;" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title style="font-family: Raleway; font-weight: 100; font-size: 21px; color: #E3E6EC; cursor: pointer;">
              Отчёты
            </mat-panel-title>
          </mat-expansion-panel-header>
          <h2 routerLink="/reportsActive" routerLinkActive="active">Активные</h2>
          <h2 routerLink="/reportsPassive" routerLinkActive="active">Пассивные</h2>
          <h2 routerLink="/reportsArchieve" routerLinkActive="active">Архивные</h2>
          <h2 routerLink="/reportsTarif" routerLinkActive="active">Тарифы</h2>
          <h2 routerLink="/reportsEquipment" routerLinkActive="active">Оборудования</h2>
          <h2 routerLink="/reportsAge" routerLinkActive="active">Возраст</h2>
          <h2 routerLink="/reportsGender" routerLinkActive="active">Пол</h2>
          <h2 routerLink="/reportRejection" routerLinkActive="active">Отказ</h2>
          <h2 routerLink="/reportOwn" routerLinkActive="active">Own report</h2>
        </mat-expansion-panel>
      </mat-accordion>
      <h2 routerLink="/transactions" routerLinkActive="active">Транзакции</h2>
      <h2 routerLink="/services" routerLinkActive="active">Услуги</h2>
      <h2 routerLink="/promotion" routerLinkActive="active">Акция</h2>
      <h2 routerLink="/users" routerLinkActive="active">Пользователи</h2>
      <h2 routerLink="/permission" routerLinkActive="active">Доступ</h2>
      <h2 routerLink="/devices" routerLinkActive="active">Девайсы</h2>
      <h2 routerLink="/group" routerLinkActive="active">Группа пользователей</h2>
      <h2 routerLink="/ipgroup" routerLinkActive="active">IP-Адрес</h2>
      <h2 routerLink="/firewall" routerLinkActive="active">FireWall</h2>
      <h2 routerLink="/routes" routerLinkActive="active">Пути</h2>
      <h2 routerLink="/change-tariffs" routerLinkActive="active">Смена тарифа</h2>
    </div>
  </div>

  <div class="log_Out-block">
    <div class="colors_block">
      <h1>{{ themes_name }}</h1>
      <mat-slide-toggle [color]="color" [checked]="isCheked" (click)="chageThemesName()"></mat-slide-toggle>
    </div>
  </div>
</div>

<div class="navigation_container">
  <div class="navigation_block" (click)="navAnime()">
    <div class="stick" [@navigation]="line ? 'after' : 'before' "></div>
    <div class="stick" [@navigation]="line ? 'nextAfter' : 'nextBefore' "></div>
    <div class="stick" [@navigation]="line ? 'lastAfter' : 'lastBefore' "></div>
  </div>
  <h1 class="log_Out" (click)="logOut()">Выход</h1>
</div>
