import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class RequestsService {

  // private url = 'https://api.nets.tj'
  // private url = 'http://45.94.219.6:12345'
  private url = 'http://185.121.0.13:12123'
  // private url = 'http://185.121.0.24:8081'
  // private url = 'http://45.94.219.6:1212'

  // private url = 'https://api.nets.tj'
  // private url = 'http://45.94.219.6:12345'

  // http://45.94.219.6:12345
  //http://45.94.219.6:12345
  // https://api.nets.tj


  constructor(private http: HttpClient, private router: Router) {}

  // Auth
  authRequest(login: any, password: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    return this.http.post(this.url + '/api/login', {"login": login, "password": password}, {headers: header})
  }
  refreshTokenRequest(refresh_token: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    return this.http.post( this.url + "/api/refresh", {"refresh_token": refresh_token}, {headers: header})
  }

  // Error

  error(error: any) {
    if(error.status == 401) {
      this.refreshTokenRequest(localStorage.getItem('refresh_token')).subscribe( (response: any) => {
        localStorage.setItem('access_token', response.access_token)
        localStorage.setItem('refresh_token', response.refresh_token)
        location.reload()
      }, error => {
        localStorage.clear()
        this.router.navigate(['/'])
      })
    } else if(error.status == 403){
      alert('У вас нет прав')
      // location.reload()
    } else if(error.status >= 500){
      alert('Айби Кобилай ошибка 500 сервер не отвечает')
      location.reload()
    } else if(error.status == 400){
      alert('ошибка на стороне клиента')
      location.reload()
    }else if(error.status == 404){
      alert('Несуществующий эндпоинт 404')
      location.reload()
    } else{
      alert('Непредвиденная ошибка')
      location.reload()
    }
  }

  // refreshRequest(error: HttpErrorResponse) {
  //   let header: HttpHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //   })
  //   let token = localStorage.getItem('refresh_token')
  //   console.log(error)
  //   if(token!= null){
  //     this.http.post(this.url + '/refresh',  {"refresh_token": token}, {headers: header}).pipe(
  //     catchError((err)=>this.refreshRequest(err)) // then handle the error
  //   )
  //     .subscribe( (response: any) => {
  //           localStorage.setItem('access_token', response.access_token)
  //           localStorage.setItem('refresh_token', response.refresh_token)
  //           console.log(response);
  //         }, error => {
  //           localStorage.clear()
  //           console.log(error)
  //         })
  //   }
  //       return throwError(error);
  // }

  // End of Auth

  // Tarifs
  getTarifsRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/tariff', {headers: header})
  }


  getFilterTarifsRequest(name: string, price: string, score: string, speed: string, comment: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/tariff?name=*${name}*&price=${price}&score=${''+score}&speed=${speed}&comment=${comment}`, {headers: header})
  }

  postTarifsRequest(name: string, price: number, score: number, speed: number, comment: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/tariff', {"name": name, "price": price, "score": ''+score, "speed": speed, "comment": comment}, {headers: header})
  }

  putTarifsRequest(id: number, name: string, price: number, score: number, speed: number, comment: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/tariff', {"id": id, "name": name, "price": price, "score": ''+score, "speed": speed, "comment": comment}, {headers: header})
  }

  deleteTarifsRequest(id: number) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.delete(this.url + '/api/tariff?id=' + id, {headers: header})
  }
  // End of Tarifs

  // Accounts
  getAccountsRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/account', {headers: header})
  }

  getAccountsByIdRequest(id: number) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/account?accounts.id=' + id, {headers: header})
  }
  getDisabledIdRequest(id: number) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/account/disable/history?account_id=' + id, {headers: header})
  }

  getFilterAccountsRequest(id: any, fio: any, tarif_id: any, speed_cf: any, phone_number: any, ipaddress: any, acc_info: any, conf_firewall_id: any, passport: any, age: any, gender: any, device_type_id: any, overdraft: any, customr_type: any, comment: any, status: any, start_created: any, end_created: any, start_date: any, end_date: any, connect_by: any, contact_by: any, page_size: any, account_friend_id: any, city_s: any, district_s: any, microdistrict_s: any, street_s: any, home_s: any, apartment_s: any,) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/account?accounts.id=${id}&accounts.fio=*${fio}*&tarif_id=${tarif_id}&speed_cf=${speed_cf}&phone_number=*${''+phone_number}*&ipaddress=${ipaddress}&acc_info=${acc_info}&conf_firewall_id=${conf_firewall_id}&passport=*${passport}*&age=${age}&gender=${gender}&device_type_id=${device_type_id}&overdraft=${overdraft}&accounts.customr_type=${customr_type}&comment=${comment}&status=${status}&accounts.created_at>=${start_created}&accounts.created_at<=${end_created}&end_date>=${start_date}&end_date<=${end_date}&connect_user_id=${connect_by}&contact_user_id=${contact_by}&page_size=${page_size}&account_friend_id!=${account_friend_id}&city_s=${city_s}&district_s=${district_s}&microdistrict_s=${microdistrict_s}&street_s=${street_s}&home_s=${home_s}&apartment_s=${apartment_s}`, {headers: header})
  }
  getFilterRejectionRequest(status:any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/account?account_status_id=3`, {headers: header})
  }
  getFilterOwnRequest(date_from: any, date_to: any ) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/report/15?date_from=${date_from}&date_to=${date_to}`, {headers: header})
  }
  getFilterAccountsB2BRequest(id: any, fio: any, tarif_id: any, speed_cf: any, phone_number: any, ipaddress: any, acc_info: any, conf_firewall_id: any, passport: any, age: any, gender: any, device_type_id: any, overdraft: any, customr_type: any, comment: any, status: any, start_created: any, end_created: any, start_date: any, end_date: any, connect_by: any, contact_by: any, page_size: any, org_name: any, city_s: any, district_s: any, microdistrict_s: any, street_s: any, home_s: any, apartment_s: any,) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/account/b2b?accounts.id=${id}&accounts.fio=${fio}&tarif_id=${tarif_id}&speed_cf=${speed_cf}&phone_number=*${''+phone_number}*&ipaddress=${ipaddress}&acc_info=${acc_info}&conf_firewall_id=${conf_firewall_id}&passport=*${passport}*&age=${age}&gender=${gender}&device_type_id=${device_type_id}&overdraft=${overdraft}&accounts.customr_type=${customr_type}&comment=${comment}&status=${status}&accounts.created_at>=${start_created}&accounts.created_at<=${end_created}&end_date>=${start_date}&end_date<=${end_date}&connect_user_id=${connect_by}&contact_user_id=${contact_by}&page_size=${page_size}&org_name=${org_name}&city_s=${city_s}&district_s=${district_s}&microdistrict_s=${microdistrict_s}&street_s=${street_s}&home_s=${home_s}&apartment_s=${apartment_s}`, {headers: header})
  }
  getFilterAccountsHostingRequest(id: any, login: any, fio: any,  phone_number: any, comment: any, acc_info: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/account?accounts.id=${id}&accounts.login=${login}&fio=${fio}&phone_number=*${''+phone_number}*&comment=${comment}&acc_info=${acc_info}`, {headers: header})
  }

  liner : any
  postAccountsRequest(fio: string, tarif_id: number, price_cf: any, speed_cf: any, phone_number: string, end_date: any, ipaddress: string, acc_info: string, conf_firewall_id: number, passport: any, age: any, gender: any, device_type_id: any, overdraft: any, customr_type: any, comment: string, connect_by: any, contact_by: any, table_id: any, connect_type_id: any, promo_id: any,  comment_1: any, city_s : any, district_s: any, microdistrict_s: any, street_s: any, home_s: any, apartment_s: any,) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/account', {"fio":fio, "tarif_id": tarif_id * 1, "price_cf": price_cf, "speed_cf": speed_cf, "phone_number": ''+phone_number, "end_date": end_date, "ipaddress": ipaddress, "acc_info": acc_info, "conf_firewall_id": conf_firewall_id, "passport": passport, "age": age, "gender": gender, "device_type_id": device_type_id, "overdraft": overdraft, "customr_type": customr_type, "comment":comment, "connect_user_id": connect_by*1, "contact_user_id": contact_by*1, "table_id": table_id, "connect_type_id": connect_type_id, "promo_id": promo_id, "comment_1": comment_1, "city_s": city_s, "district_s": district_s, "microdistrict_s": microdistrict_s + '', "street_s": street_s, "home_s": home_s, "apartment_s": apartment_s,}, {headers: header})
  }



  postAccountsB2BRequest(fio: string, tarif_id: number, price_cf: any, speed_cf: any, phone_number: string,  inn:any , org_name: any,  ipaddress: string, acc_info: string, conf_firewall_id: number,  device_type_id: any, overdraft: any, customr_type: any, comment: string, connect_by: any, contact_by: any, table_id: any, connect_type_id: any, promo_id: any, comment_1: any, city_s : any, district_s: any, microdistrict_s: any, street_s: any, home_s: any, apartment_s: any,) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/account', {"fio":fio, "tarif_id": tarif_id * 1, "price_cf": price_cf, "speed_cf": speed_cf, "phone_number": ''+phone_number, "inn": ''+inn, "org_name": org_name, "ipaddress": ipaddress, "acc_info": acc_info, "conf_firewall_id": conf_firewall_id,"device_type_id": device_type_id, "overdraft": overdraft, "customr_type": customr_type, "comment":comment, "connect_user_id": connect_by*1, "contact_user_id": contact_by*1, "table_id": table_id, "connect_type_id": connect_type_id, "promo_id": promo_id, "comment_1": comment_1, "city_s": city_s, "district_s": district_s, "microdistrict_s": microdistrict_s + '', "street_s": street_s, "home_s": home_s, "apartment_s": apartment_s,}, {headers: header})
  }
  postAccountsHostingRequest(login: string, fio: number, phone_number: any, comment: any, acc_info: string, ) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/account', {"login":login, "fio": fio, "phone_number": ''+phone_number, "comment": comment, "acc_info": acc_info}, {headers: header})
  }



  putAccountsRequest(id: number, fio: string, tarif_id: number, price_cf: any, speed_cf: any, phone_number: string, end_date: any, ipaddress: string, acc_info: string, conf_firewall_id: number, passport: any, age: any, gender: any, device_type_id: any, overdraft: any, customr_type: any, comment: string, connect_by: any, contact_by: any, table_id: any, connect_type_id: any, promo_id: any, comment_1: any, city_s : any, district_s: any, microdistrict_s: any, street_s: any, home_s: any, apartment_s: any,) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/account', {"id": id, "fio":fio, "tarif_id": tarif_id * 1, "price_cf": price_cf, "speed_cf": speed_cf, "phone_number": ''+phone_number, "end_date": end_date, "ipaddress": ipaddress, "acc_info": acc_info, "conf_firewall_id": conf_firewall_id, "passport": passport, "age": age, "gender": gender, "device_type_id": device_type_id, "overdraft": overdraft, "customr_type": customr_type, "comment":comment,"connect_user_id":connect_by*1,"contact_user_id":contact_by*1, "table_id": table_id, "connect_type_id": connect_type_id, "promo_id": promo_id, "comment_1": comment_1, "city_s": city_s, "district_s": district_s, "microdistrict_s": microdistrict_s, "street_s": street_s, "home_s": home_s, "apartment_s": apartment_s,}, {headers: header})
  }

  putAccountsB2BRequest(id: number, fio: string, tarif_id: number, price_cf: any, speed_cf: any, phone_number: string,org_name: any, inn: any, end_date: any, ipaddress: string, acc_info: string, conf_firewall_id: number,   device_type_id: any, overdraft: any, customr_type: any, comment: string, connect_by: any, contact_by: any, table_id: any, connect_type_id: any, promo_id: any, comment_1: any, city_s : any, district_s: any, microdistrict_s: any, street_s: any, home_s: any, apartment_s: any,) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/account', {"id": id, "fio":fio, "tarif_id": tarif_id * 1, "price_cf": price_cf, "speed_cf": speed_cf, "phone_number": ''+phone_number,"org_name":org_name,"inn": ''+inn, "end_date": end_date, "ipaddress": ipaddress, "acc_info": acc_info, "conf_firewall_id": conf_firewall_id,  "device_type_id": device_type_id, "overdraft": overdraft, "customr_type": customr_type, "comment":comment,"connect_user_id":connect_by*1,"contact_user_id":contact_by*1, "table_id": table_id, "connect_type_id": connect_type_id, "promo_id": promo_id, "comment_1": comment_1, "city_s": city_s, "district_s": district_s, "microdistrict_s": microdistrict_s, "street_s": street_s, "home_s": home_s, "apartment_s": apartment_s,}, {headers: header})
  }
  putAccountsHostingRequest(id: number, login: string, fio: number, phone_number: any, comment: any, acc_info: string,) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/account', {"id": id, "login":login, "fio": fio, "phone_number": ''+phone_number, "comment": comment, "acc_info": acc_info}, {headers: header})
  }


  deleteAccountsRequest(id: number) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.delete(this.url + '/api/account?id=' + id, {headers: header})
  }
  deleteAccountsHostingRequest(id: number) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.delete(this.url + '/api/account?id=' + id, {headers: header})
  }

  getAccountRefresh(id: number) {
     let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/account/refresh?id=' + id, {headers: header})
  }
  getshares(t_id: number) {
     let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/additional_traffic/get_additional_speed?t_id=' + t_id, {headers: header})
  }

  getAccountBlockOff(id: number) {
     let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/account/off?id=' + id, {headers: header})
  }
  getAccountDisabled(id: number, info: any) {
    let header: HttpHeaders = new HttpHeaders({
     'Content-Type': 'application/json',
     'Authorization': `${localStorage.getItem('access_token')}`
   })
   return this.http.get(this.url + `/api/account/disable?id=${id}&info=${info}`, {headers: header})
 }
  getAccountDisabledHistory() {
    let header: HttpHeaders = new HttpHeaders({
     'Content-Type': 'application/json',
     'Authorization': `${localStorage.getItem('access_token')}`
   })
   return this.http.get(this.url + `/api/disable/history`, {headers: header})
 }

  getAccountBlockOn(id: number) {
     let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/account/on?id=' + id, {headers: header})
  }

  // Additional Ip
  getAdditionalIpRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/additional_ip', {headers: header})
  }
  getAdditionalIpRequests(id: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/additional_ip?account_id=${id}`, {headers: header})
  }

  postAdditionalIpRequest(account_id: any, ip: any, address: any, comment: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/additional_ip', {"account_id": account_id, "ip":ip, "address":address ,"comment":comment }, {headers: header})
  }
  putAccountsIpRequest(id: number, address: string, comment: any, ip: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/additional_ip', {"id": id, "address":address, "comment":comment, "ip": ip}, {headers: header})
  }
  deleteAdditionalIpRequest(id: number) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.delete(this.url + '/api/additional_ip?id=' + id, {headers: header})
  }
  // End of Additional Ip

  // Additional Info
  getAdditionalInfoRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/account_additional_info', {headers: header})
  }

  postAdditionalInfoRequest(account_id: number, city_id: any, district_id: any, street_id: any, street_number: any, street_apartment: any, connect_type_id: any, сonnect_user_id: any, contact_user_id: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/account_additional_info', {"account_id": account_id, "city_id": city_id,  "district_id": district_id,  "street_id": street_id,  "street_number": street_number, "street_apartment": street_apartment, "connect_type_id": connect_type_id,  "сonnect_user_id": сonnect_user_id, "contact_user_id": contact_user_id
      }, {headers: header})
  }
  postPromotioRequest(account_id: number, promotion: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/promotion/history', {"account_id": account_id, "promotion_id" : promotion }, {headers: header})
  }

  putAdditionalInfoRequest(id: number, account_id: number, city_id: any, district_id: any, street_id: any, street_number: any, street_apartment: any, connect_type_id: any, сonnect_user_id: any, contact_user_id: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/account_additional_info', {"id": id, "account_id": account_id, "city_id": city_id,  "district_id": district_id,  "street_id": street_id,  "street_number": street_number, "street_apartment": street_apartment, "connect_type_id": connect_type_id,  "сonnect_user_id": сonnect_user_id, "contact_user_id": contact_user_id}, {headers: header})
  }
  // End of Additional Info

  // Extension
  putExtensionRequest(account_id: number, end_date: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/account/end_date_update', {"account_id": account_id, "end_date": end_date}, {headers: header})
  }
  // End of Extension

  // Reports

  getReport1Request() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/report/7', {headers: header})
  }

  getFilterReport1Request(date_from: any, date_to: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/report/7?date_from=${date_from}&date_to=${date_to}`, {headers: header})
  }

  getReport2Request() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/report/10', {headers: header})
  }

  getFilterReport2Request(date_from: any, date_to: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/report/10?date_from=${date_from}&date_to=${date_to}`, {headers: header})
  }

  getReport3Request() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/report/11', {headers: header})
  }

  getFilterReport3Request(date_from: any, date_to: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/report/11?date_from=${date_from}&date_to=${date_to}`, {headers: header})
  }
  // End of Reports

  // Content_Types
  getContentTypeRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/conn_type', {headers: header})
  }
  // End of Connect Types

  // End of Accounts

  // Transactions
  getTransactionsRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/transaction', {headers: header})
  }
  getUseCauntryRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + 'api/conf_firewal', {headers: header})
  }

  getFilterTransactionsRequest(id: string, operator: string, txn_date: string, txn_id: string, comment: string,  start_date: any, end_date: any, firewall: any, transaction_type_id: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    console.log(firewall)
    return this.http.get(this.url + `/api/transaction?acc_id=${id}&operator=*${operator}*&txn_date=${txn_date}&txn_id=${txn_id}&comment=${comment}&date>=${start_date}&date<=${end_date}&conf_firewall_id=${firewall}&transaction_type_id=${transaction_type_id}`, {headers: header})
  }

  postTransactionsRequest(account_id: any, operator: string, sum: number, score: number, date: Date, txn_date: any, txn_id: any, comment: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/transaction', {"account_id": account_id, "operator": operator, "sum": sum, "score": score, "date": date, "txn_date": txn_date, "txn_id": txn_id, "comment": comment}, {headers: header})
  }

  postTransactions2Request(account_id: any, sum: number, comment: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/transaction', {"account_id": account_id, "sum": sum, "comment": comment}, {headers: header})
  }
  cancelTransactionRequest(tr_id: any, comment: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    alert(localStorage.getItem('access_token'));
    return this.http.post(this.url + `/api/transaction/rollback?tr_id=${tr_id}&comment=${comment}`, null,{headers: header})

  }




  // End of Transactions

  // Change History
  getChangeHistoryRequest(id: number) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/account/history?account_id=' + id, {headers: header})
  }
  // End of Change History

  // Services
  getServicesRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/service', {headers: header})
  }
  getReporteRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/product', {headers: header})
  }

  getFilterServicesRequest(name: string, price: string, score: string, month_count: string, comment: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/service?name=*${name}*&price=${price}&score=${''+score}&month_count=${month_count}&comment=${comment}`, {headers: header})
  }

  postServicesRequest(name: string, price: number, score: number, month_count: number, comment: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/service', {"name": name, "price": price, "score": ''+score, "month_count": month_count, "comment": comment}, {headers: header})
  }

  putServicesRequest(id: number, name: string, price: number, score: string, month_count: number, comment: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/service', {"id": id, "name": name, "price": price, "score": '' +score, "month_count": month_count, "comment": comment}, {headers: header})
  }

  deleteServicesRequest(id: number) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.delete(this.url + '/api/service?id=' + id, {headers: header})
  }
  // End of Services

  // Users
  getUsersRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/user', {headers: header})
  }

  getFilterUsersRequest(fio: string, login: string, role?: number) {
    let roleS = "";
    if(role!==undefined && role>0){
      roleS = `group_id=${role}`;
    }

    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/user?fio=*${fio}*&login=${login}&${roleS}`, {headers: header})
  }

  postUsersRequest(fio: string, login: string, password: string, role: number) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/user', {"fio": fio, "login": login, "password": password, "group_id": role}, {headers: header})
  }

  putUsersRequest(id: number, fio: string, login: string, password: string, role: number) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/user', {"id": id, "fio": fio, "login": login, "password": password, "group_id": role}, {headers: header})
  }

  deleteUsersRequest(id: number) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.delete(this.url + '/api/user?id=' + id, {headers: header})
  }

  // Permission
  getFilterPermissionRequest(id: any, name: any, rout_id: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/permission?id=${id}&name=${name}&rout_id=${rout_id}`, {headers: header})
  }

  getPermissionRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/permission', {headers: header})
  }

  postPermissionRequest( user_id: number, group_id: number, rout_id: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/permission', {"user_id": user_id, "group_id": group_id, "rout_id": rout_id}, {headers: header})
  }

  putPermissionRequest(id: number, user_id: number, group_id: number, rout_id: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/permission', {"id": id, "user_id": user_id, "group_id": group_id, "rout_id": rout_id}, {headers: header})
  }

  deletePermissionRequest(id: number) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.delete(this.url + '/api/permission?id=' + id, {headers: header})
  }

  // Permission Routes
  getPermissionRouteRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/permission/routes', {headers: header})
  }
  // End Permission Route

  // End of Permission

  // End of Users

  // Service Link

  getFilterServiceLinkRequest(account_id: any, fio: any, created_at: any, expire_at: any, next_payment: any, service_id: string, price: any, deleted: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/service_link?account_id=${account_id}&fio=${fio}&service_account_links.created_at>=${created_at}&service_account_links.expire_at<=${expire_at}&next_payment=${next_payment}&service_id=${service_id}&price=${price}&service_account_links.deleted=${deleted}`, {headers: header})
  }

  getServiceLinkRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/service_link', {headers: header})
  }

  getServiceLinkRequestByID(id: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/service_link?account_id=' + id, {headers: header})
  }
  getServiceRequestByID(id: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/service_link?account_id=' + id, {headers: header})
  }
  getReportsRequestByID(id: number) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/product_sale?account_id=' + id, {headers: header})
  }
  getServsRequestByID(id: number) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/service_link/debit?id=' + id, {headers: header})
  }


  posServiceLinkRequest(account_id: any, created_at: any, service_id: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/service_link', {"account_id": account_id, "created_at": created_at, "service_id": service_id * 1}, {headers: header})
  }
  postReporteRequest(product_id: any, account_id: number,  count: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/product_sale', {"product_id": +product_id, "account_id": account_id,  "count": count * 1}, {headers: header})
  }

  putServiceLinkRequest(id: number, fio: string, login: string, password: string, role: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/service_link', {"id": id, "fio": fio, "login": login, "password": password, "role": role}, {headers: header})
  }

  deleteServiceLinkRequest(id: number) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.delete(this.url + '/api/service_link?id=' + id, {headers: header})
  }
  // End of Service Link

  // Credit
  getcreditByID(id: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/credit?account_id=' + id, {headers: header})
  }
  getCreditRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/credit', {headers: header})
  }

  // getdataRequest() {
  //   let header: HttpHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `${localStorage.getItem('access_token')}`
  //   })
  //   return this.http.get('http://10.251.26.210:7777/', {headers: header})

  // }

  postCreditRequest(account_id: any, comment: any, expire_at: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/credit', {"account_id": account_id, "comment": comment, "expire_at": expire_at}, {headers: header})
  }

  putCreditRequest(id: number, account_id: any, comment: any, expire_at: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/credit', {"id": id, "account_id": account_id, "comment": comment, "expire_at": expire_at}, {headers: header})
  }
  // End of Credit

  // Devices
  getDeviceRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/device_type', {headers: header})
  }

  getFilterDeviceRequest(id: number, name: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/device_type?id=${id}&name=${name}`, {headers: header})
  }
  getFilterVlanRequest(id: number, ip_start: any, last_ip: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/vlan?id=${id}&ip_start=${ip_start}&last_ip=${last_ip}`, {headers: header})
  }
  getFilterAccessCameraRequest(id: number, ip_start: any, last_ip: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/camera_cust_access?id=${id}&ip_start=${ip_start}&last_ip=${last_ip}`, {headers: header})
  }
  getFilterCameraRequest(id: number, ip_start: any, last_ip: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/camera?id=${id}&ip_start=${ip_start}&last_ip=${last_ip}`, {headers: header})
  }
  getFilterReportsProductRequest(account_id: any,count: any, created_by: any, fio: any, id: number, name: any, summ: any,  ) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/product_sale?account_id=${account_id}&count=${count}&created_by=${created_by}&fio=${fio}&id=${id}&name=${name}&summ=${summ}`, {headers: header})
  }
  getFilterProductRequest(id: number, name: any, unit: any, price: any, score: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/product?id=${id}&name=${name}&unit=${unit}&price=${price}&score=${''+score}`, {headers: header})
  }
  getFilterProductOltRequest(id: number,type: any, name: any, host: any, port: any, user: any, password: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/olt?id=${id}&type=${type}&name=${name}&host=${host}&port=${port}&user=${user}&password=${password}`, {headers: header})
  }
  getFilterProductSomonTVRequest(login: number,first_name: any, last_name: any, middle_name: any, password: any, comment: any, birthday: any, birthplace: any, passport_issue_date:any, passport_issue_place:any, passport_number: any, passport_series: any, document_date: any, document_number: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/somon_tv/get?login=${login}&first_name=${first_name}&last_name=${last_name}&middle_name=${middle_name}&password=${password}&comment=${comment}&birthday=${birthday}&birthplace=${birthplace}&passport_issue_date=${passport_issue_date}&passport_issue_place=${passport_issue_place}&passport_number=${passport_number}&passport_series=${passport_series}&document_date=${document_date}&document_number=${document_number}`, {headers: header})
  }
  getFilterProxyRequest(id: number, name: any, price: any, score: any, comment: any,) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/prox_service?id=${id}&name=${name}&price=${price}&score=${score}&comment=${comment}`, {headers: header})
  }
  getFilterVpsRequest(id: number, name: any, price: any, score: any, comment: any,) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/cloud_service?id=${id}&name=${name}&price=${price}&score=${score}&comment=${comment}`, {headers: header})
  }
  getFilterIspRequest(id: number, name: any, price: any, score: any, comment: any,) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/isp_service?id=${id}&name=${name}&price=${price}&score=${score}&comment=${comment}`, {headers: header})
  }
  getFilterSaleProxyRequest(id: number, account_id: any, service_id: any, created_by: any, price_cf: any,created_at: any, next_payment:any, deleted: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/prox_service_account_link?id=${id}&account_id=${account_id}&service_id=${service_id}&created_by=${created_by}&price_cf=${price_cf}&created_at=${created_at}&next_payment=${next_payment}&deleted=${deleted}`, {headers: header})
  }
  getFilterSaleVpsRequest(id: number, account_id: any, service_id: any, created_by: any, price_cf: any,created_at: any, next_payment:any, deleted: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/cloud_service_account_link?id=${id}&account_id=${account_id}&service_id=${service_id}&created_by=${created_by}&price_cf=${price_cf}&created_at=${created_at}&next_payment=${next_payment}&deleted=${deleted}`, {headers: header})
  }
  getFilterSaleIspRequest(id: number, account_id: any, service_id: any, created_by: any, price_cf: any,created_at: any, next_payment:any, deleted: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/isp_service_account_link?id=${id}&account_id=${account_id}&service_id=${service_id}&created_by=${created_by}&price_cf=${price_cf}&created_at=${created_at}&next_payment=${next_payment}&deleted=${deleted}`, {headers: header})
  }

  postDeviceRequest(name: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/device_type', {"name": name}, {headers: header})
  }
  postVlanRequest(id: any, ip_start:string, last_ip: any, gate_way: any, mask: any,) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/vlan', {"id": id, "last_ip": last_ip, "ip_start": ip_start, "gate_way":gate_way,"mask":mask}, {headers: header})
  }
  postCameraRequest(ip_start:string, address: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/camera', { "ip_addres": ip_start , "address": address}, {headers: header})
  }
  postReposrtsProductRequest(account_id:any, count: any, created_by:any, fio: any, name: any, summ: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/product_sale', {"account_id": account_id,"count": count, "created_by": created_by, "fio": fio, "name": name, "summ": summ}, {headers: header})
  }
  postProductRequest(name: any, unit:any, price: number, score: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/product', {"name": name, "unit": unit, "price": price, "score": ''+score}, {headers: header})
  }

  putDeviceRequest(id: number, name: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/device_type', {"id": id, "name": name}, {headers: header})
  }
  putVlanRequest(id: number, ip_start: string,last_ip: string, gate_way: any, mask: any,) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/vlan', {"id": id, "ip_start": ip_start, "last_ip": last_ip, "gate_way": gate_way, "mask": mask}, {headers: header})
  }
  putCameraRequest(id: number, ip_start: string, address: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/camera', {"id": id, "ip_addres": ip_start, "address": address}, {headers: header})
  }
  putAccessCameraFalseRequest(id: number, ip_start: boolean,) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/camera_cust_access/access', {"id": id, "access": ip_start,}, {headers: header})
  }
  putProductRequest(id: number, name: any, price: number, unit: any, score: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/product', {"id": id, "name": name, "price": price,"unit": unit, "score": ''+score}, {headers: header})
  }
  putOltRequest(id: number, type: number, name: any, host: number, port: any, user: any, password: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/olt', {"id": id, "type": type,"name": name, "host": host,"port": port, "user": user, "password": password}, {headers: header})
  }
  putSomonTVRequest(login: any, first_name: any, last_name: any, middle_name: any, birthday: any, comment: any, password: any, birthplace: any, passport_issue_date: any, passport_issue_place: any, passport_number: any, passport_series: any, document_date: any, document_number: any, till: any, customer_id: any ,personal_account_id: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/somon_tv/update', {"login": login, "first_name": first_name,"last_name": last_name, "middle_name": middle_name,"password": password, "comment": comment, "birthday": birthday, "birthplace": birthplace, "passport_issue_date": passport_issue_date, "passport_issue_place": passport_issue_place, "passport_number": passport_number, "passport_series": passport_series, "document_date": document_date, "document_number": document_number, "customer_id": customer_id,"personal_account_id": personal_account_id}, {headers: header})
  }
  putSaleProxyRequest(id: number, account_id: number, service_id: number, price_cf: number,) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/prox_service_account_link', {"id": id, "account_id": account_id,"service_id": service_id, "price_cf": price_cf}, {headers: header})
  }
  putDateSomonTVRequest(login: any, till: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/somon_tv/update_till', {"login": login, "till": till}, {headers: header})
  }
  putSaleVpsRequest(id: number, account_id: number, service_id: number, price_cf: number,) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/cloud_service_account_link', {"id": id, "account_id": account_id,"service_id": service_id, "price_cf": price_cf}, {headers: header})
  }
  putSaleIspRequest(id: number, account_id: number, service_id: number, price_cf: number,) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/isp_service_account_link', {"id": id, "account_id": account_id,"service_id": service_id, "price_cf": price_cf}, {headers: header})
  }
  putProxyRequest(id: number, name: any, price: number, score: any, comment: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/prox_service', {"id": id,"name": name, "price": price,"score": score, "comment": comment}, {headers: header})
  }
  putVpsRequest(id: number, name: any, price: number, score: any, comment: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/cloud_service', {"id": id,"name": name, "price": price,"score": score, "comment": comment}, {headers: header})
  }
  putIspRequest(id: number, name: any, price: number, score: any, comment: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/isp_service', {"id": id,"name": name, "price": price,"score": score, "comment": comment}, {headers: header})
  }

  deleteDeviceRequest(id: number) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.delete(this.url + '/api/device_type?id=' + id, {headers: header})
  }
  deleteVlanRequest(id: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.delete(this.url + '/api/vlan?id=' + id, {headers: header})
  }
  deleteAcsessCameraRequest(id: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.delete(this.url + '/api/camera_cust_access?id=' + id, {headers: header})
  }
  deleteCameraRequest(id: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.delete(this.url + '/api/camera?id=' + id, {headers: header})
  }
  deleteReportRequest(id: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.delete(this.url + '/api/product_sale?id=' + id, {headers: header})
  }
  deleteProuctRequest(id: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.delete(this.url + '/api/product?id=' + id, {headers: header})
  }
  deleteSomonTvRequest(login: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.delete(this.url + '/api/somon_tv/delete?login=' + login, {headers: header})
  }
  deleteSaleProxyRequest(id: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.delete(this.url + '/api/prox_service_account_link?id=' + id, {headers: header})
  }
  deleteSaleVpsRequest(id: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.delete(this.url + '/api/cloud_service_account_link?id=' + id, {headers: header})
  }
  deleteIspProxyRequest(id: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.delete(this.url + '/api/isp_service_account_link?id=' + id, {headers: header})
  }
  deleteProxyRequest(id: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.delete(this.url + '/api/prox_service?id=' + id, {headers: header})
  }
  deleteVpsRequest(id: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.delete(this.url + '/api/cloud_service?id=' + id, {headers: header})
  }
  deleteIspRequest(id: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.delete(this.url + '/api/isp_service?id=' + id, {headers: header})
  }
  // End of Devices


  // Devices
  getRoleRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/group', {headers: header})
  }

  getFilterRoleRequest(id: number, name: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/group?id=${id}&name=${name}`, {headers: header})
  }

  postRoleRequest(name: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/group', {"name": name}, {headers: header})
  }

  putRoleRequest(id: number, name: string) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/group', {"id": id, "name": name}, {headers: header})
  }
  // End of Devices
  postRoutesRequest(dataUrl: string, body: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    })

    return this.http.post(this.url + dataUrl, body, {headers: header})
  }
  getRoutesRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/permission/routes', {headers: header})
  }

  // getFilterRoutesRequest(fio: string, login: string, role: string) {
  //   let header: HttpHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `${localStorage.getItem('access_token')}`
  //   })
  //   return this.http.get(this.url + `/api/user?fio=*${fio}*&login=${login}&role=${role}`, {headers: header}).pipe(

  // }
  // Promotion
  getPromotionRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/promotion', {headers: header})
  }

  getFilterPromotionRequest(id: number, name: string, day_count: any, deleted: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/promotion?id=${id}&name=${name}&day_count=${day_count}&deleted=${deleted}`, {headers: header})
  }

  postPromotionRequest(name: string, day_count: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/promotion', {"name": name, "day_count": day_count}, {headers: header})
  }

  putPromotionRequest(id: number, name: string, day_count: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/promotion', {"id": id, "name": name, "day_count": day_count}, {headers: header})
  }

  deletePromotionRequest(id: number) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.delete(this.url + '/api/promotion?id=' + id, {headers: header})
  }
  // End of Promotion


  // IP Group
  getIPGroupRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/ip_group', {headers: header})
  }

  getFilterIPGroupRequest(id: number, ip_start: any, ip_mask: any, last_ip: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/ip_group?id=${id}&ip_start=${ip_start}&ip_mask=${ip_mask}&last_ip=${last_ip}`, {headers: header})
  }

  postIPGroupRequest(ip_start: any, ip_mask: any, last_ip: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/ip_group', {"ip_start": ip_start, "ip_mask": ip_mask, "last_ip": last_ip}, {headers: header})
  }

  putIPGroupRequest(id: number, ip_start: any, ip_mask: any, last_ip: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/ip_group', {"id": id, "ip_start": ip_start, "ip_mask": ip_mask, "last_ip": last_ip}, {headers: header})
  }

  deleteIPGroupRequest(id: number) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.delete(this.url + '/api/ip_group?id=' + id, {headers: header})
  }

  // Next IP
  getNextIPRequest(id: number) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/ip_group/next_ip?id=' + id, {headers: header})
  }
  getFirewallRequest(id: number) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/account/firewall_update?id=' + id, {headers: header})
  }
  // End of Next IP

  // End of IP Group


  // FireWall
  getFireWallRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/conf_firewall', {headers: header})
  }

  getFilterFireWallRequest(id: number, firewall_host: any, firewall_port: any, firewall_user: any, firewall_password: any, firewall_dysh_path: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/conf_firewall?ID=${id}&firewall_host=${firewall_host}&firewall_port=${firewall_port}&firewall_user=${firewall_user}&firewall_password=${firewall_password}&firewall_dysh_path=${firewall_dysh_path}`, {headers: header})
  }
  getFireWalRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/conf_firewall`, {headers: header})
  }
  getTranzaktionRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + `/api/transaction_type`, {headers: header})
  }

  postFireWallRequest(firewall_host: any, firewall_port: any, firewall_user: any, firewall_password: any, firewall_dysh_path: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/conf_firewall', {"firewall_host": firewall_host, "firewall_port": firewall_port, "firewall_user": firewall_user, "firewall_password": firewall_password, "firewall_dysh_path": firewall_dysh_path}, {headers: header})
  }

  putFireWallRequest(ID: any, firewall_host: any, firewall_port: any, firewall_user: any, firewall_password: any, firewall_dysh_path: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + '/api/conf_firewall', {"ID": ID, "firewall_host": firewall_host, "firewall_port": firewall_port, "firewall_user": firewall_user, "firewall_password": firewall_password, "firewall_dysh_path": firewall_dysh_path}, {headers: header})
  }

  // deleteFireWallRequest(id: number) {
  //   let header: HttpHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `${localStorage.getItem('access_token')}`
  //   })
  //   return this.http.delete(this.url + '/api/conf_firewall?id=' + id, {headers: header}).pipe(
 // then handle the error

  // }
  // End of FireWall





  getChangeTarifsRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/tarif_chage', {headers: header})
  }
  putChangeTarifsRequest(id: number, status:number) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + `/api/tarif_chage?t_id=${id}&status=${status}`,null, {headers: header})
  }
  putConfirmationB2BRequest(id: number) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.put(this.url + `/api/account_additional_info/sell?id=${id}`,null, {headers: header})
  }
  getPacketRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/additional_traffic', {headers: header})
  }
  getPacketByID(id: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/accs_additional_traffic?account_id=' + id, {headers: header})
  }
  postChangeTarifRequest(account_id: any, tarif_id: any, update_date: any, status: any ,  send_sms: any , rever_tarif: any, free: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/account/change_tarif', {"account_id": account_id, "tarif_id": +tarif_id, "update_date": update_date, "status": status,  "send_sms": send_sms, "rever_tarif": rever_tarif, "free": free}, {headers: header})

  }
  postPacketRequest(account_id: number, additional_traffic_id: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/accs_additional_traffic', {"account_id": account_id, "additional_traffic_id": additional_traffic_id}, {headers: header})
  }
  postSharesRequest(account_id: number, tarif: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/additional_traffic/add_additional_speed', {"account_id": account_id, "additional_speed_id": +tarif}, {headers: header})
  }
  getAgeRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/report/14', {headers: header})
  }

  getPackageConfirmationRequest() {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.get(this.url + '/api/accs_additional_traffic/sell', {headers: header})
  }
  postOltRequest(type:number, name: any, host:string,port:string, user: number, password: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/olt', {"type": type,"name": name, "host": host, "port": port,"user": user, "password": password}, {headers: header})
  }
  postSomonTVRequest(login:any, first_name: any, last_name:any,middle_name:any, password: any, comment: any,birthday:any, birthplace: any, passport_issue_date:any,passport_issue_place:any, passport_number: any, passport_series: any,document_date: any, document_number: any, till: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/somon_tv/create', {"login": login,"first_name": first_name, "last_name": last_name, "middle_name": middle_name,"password": password, "comment": comment,"birthday": birthday,"birthplace": birthplace, "passport_issue_date": passport_issue_date, "passport_issue_place": passport_issue_place,"passport_number": passport_number, "passport_series": passport_series,"document_date": document_date, "document_number": document_number, "till": till}, {headers: header})
  }
  postSaleProxyRequest(account_id: any, service_id:any,price_cf:any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/prox_service_account_link', {"account_id": account_id, "service_id": service_id, "price_cf": price_cf}, {headers: header})
  }
  postSaleVpsRequest(account_id: any, service_id:any,price_cf:any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/cloud_service_account_link', {"account_id": account_id, "service_id": service_id, "price_cf": price_cf}, {headers: header})
  }
  postSaleIspRequest(account_id: any, service_id:any,price_cf:any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/isp_service_account_link', {"account_id": account_id, "service_id": service_id, "price_cf": price_cf}, {headers: header})
  }
  postIspRequest(name: any, price:number,score:string, comment: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/isp_service', {"name": name, "price": price, "score": score,"comment": comment}, {headers: header})
  }
  postProxyRequest(name: any, price:number,score:string, comment: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/prox_service', {"name": name, "price": price, "score": score,"comment": comment}, {headers: header})
  }
  postVpsRequest(name: any, price:number,score:string, comment: any) {
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('access_token')}`
    })
    return this.http.post(this.url + '/api/cloud_service', {"name": name, "price": price, "score": score,"comment": comment}, {headers: header})
  }


  postDocumen(id: any){
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Autorization': `${localStorage.getItem('accsess')}`
    })
    return this.http.post(this.url + '/api/monster' ,{"id": id}, {headers:header})
  }

  getMonster(id: any){
    let header: HttpHeaders = new HttpHeaders({
      'Content-type': 'aplication/json',
      'Autarization': `${localStorage.getItem('accsess_token')}`
    })
    return this.http.get(this.url + '/api/monster')
  }




}


