import { Component, EventEmitter, OnInit, Output, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router } from '@angular/router';
import { ThemePalette } from '@angular/material/core';
import { RequestsService } from '../all.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('navigation', [
      state('before', style({ transform: 'rotate(0)' })),
      state('after', style({ transform: 'translateY(7px) rotate(-135deg)' })),
      transition('before <=> after', animate(200)),
      state('nextBefore', style({ transform: 'rotate(0)' })),
      state('nextAfter', style({ transform: 'rotate(135deg)' })),
      transition('nextBefore <=> nextAfter', animate(200)),
      state('lastBefore', style({ display: 'block' })),
      state('lastAfter', style({ display: 'none' })),
      transition('lastBefore <=> lastAfter', animate(100)),
      state('beforeNav', style({ transform: 'translateX(-100%)' })),
      state('afterNav', style({ transform: 'translateX(0)' })),
      transition('afterNav <=> beforeNav', animate(250)),
    ]),
  ],
})
export class HeaderComponent implements OnInit {
  @Output() OnAdd: EventEmitter<any> = new EventEmitter<any>();
  themes_name = 'Светлая тема';
  color: ThemePalette = 'warn';
  isDark: any;
  isCheked: any;
  panelOpenState = false;
  line = false;

  constructor(public request: RequestsService, private router: Router) {}

  ngOnInit() {
    if (localStorage.getItem('isDark')) {
      document.body.classList.toggle('dark-theme');
      this.isDark = true;
      this.isCheked = true;
    } else {
      document.body.classList.remove('dark-theme');
      this.isDark = false;
      this.isCheked = false;
    }
  }

  navAnime() {
    this.request.liner = !this.request.liner;
  }

  chageThemesName() {
    this.isDark = !this.isDark;

    if (this.isDark) {
      localStorage.setItem('isDark', this.isDark);
      document.body.classList.toggle('dark-theme');
      this.isCheked = false;
      this.themes_name = 'Тёмная тема';
    } else {
      localStorage.removeItem('isDark');
      document.body.classList.remove('dark-theme');
      this.isCheked = true;
      this.themes_name = 'Светлая тема';
    }
  }

  logOut() {
    localStorage.clear();
    this.router.navigate(['/']);
  }
  
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const isClickInside = document.querySelector('.navbar_block')?.contains(target);
    const isBurgerMenuClicked = document.querySelector('.navigation_block')?.contains(target);

    if (!isClickInside && !isBurgerMenuClicked) {
      this.request.liner = false;
    }
  }
}
